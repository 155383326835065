<template>
    <el-table v-if="damagesData.length > 0" :data="damagesData" style="width: 100%">
        <el-table-column :label="$t('clients.address')" :show-overflow-tooltip="true">
            <template slot-scope="scope">
                {{ scope.row.street }}, {{ scope.row.city }} {{ scope.row.post_code }}
            </template>
        </el-table-column>
        <el-table-column :label="$t('clients.client')">
            <template slot-scope="scope">
                <router-link :to="`/clients/${scope.row.client_uuid}/dashboard`" class="hover:text-red-500 hover:font-semibold">
                    {{ scope.row.client_name }} {{ scope.row.client_surname }}
                </router-link>
            </template>
        </el-table-column>
        <el-table-column :label="$t('clients.employee')">
            <template slot-scope="scope">
                <router-link :to="`/employees/${scope.row.employee_uuid}/information`" class="hover:text-red-500 hover:font-semibold">
                    {{ scope.row.employee_name }} {{ scope.row.employee_surname }}
                </router-link>
            </template>
        </el-table-column>
        <el-table-column prop="description" :label="$t('clients.description')" :show-overflow-tooltip="true" />
        <el-table-column prop="amount" :label="$t('clients.amount')">
            <template slot-scope="scope">
                {{ intToMoneyFormat(scope.row.amount) }}
            </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('clients.status')" width="90">
            <template slot-scope="scope">
                {{ scope.row.status === 0 ? $t('clients.open') : $t('clients.solved') }}
            </template>
        </el-table-column>
        <el-table-column prop="created_at" width="90" />
        <el-table-column width="80">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.edit')" placement="top">
                        <el-button type="primary" size="mini" @click="handleEdit(scope.row)">
                            <fa-icon :icon="['far', 'edit']" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('clients.delete')" placement="top">
                        <el-button type="danger" size="mini" @click="handleDelete(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'trash']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        damagesData: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    methods: {
        handleEdit(item) {
            this.$emit('handleEdit', item);
        },

        handleDelete(uuid) {
            this.$emit('handleDelete', uuid);
        },
    },
};
</script>
